<template>
  <v-card>
    <v-overlay :value="global_layers.overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-title v-if="!global_layers.isEditing">
      {{ $t('CustomerLayer.create') }}
    </v-card-title>
    <v-card-title v-else-if="disableForm">
      Layer
    </v-card-title>
    <v-card-title
      v-else-if="isTranslating"
    >
      Layer Translate {{ selectedLanguage }}
    </v-card-title>
    <v-card-title v-else>
      {{ $t('CustomerLayer.edit') }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="layerForm"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="layerForm.layer_name"
          :label="$t('CustomerLayer.name')"
          required
          :rules="layer_name_rules"
          :counter="255"
          :disabled="global_layers.isEditing && disableForm"
        />
        <v-textarea
          v-model="layerForm.layer_description"
          auto-grow
          class="mt-2"
          :label="$t('CustomerLayer.description')"
          :rules="description_rules"
          :counter="512"
          :disabled="global_layers.isEditing && disableForm"
        />
        <div v-if="!isTranslating">
          <v-radio-group
            v-model="global_layers.fileChoice"
            row
          >
            <v-radio
              label="GeoJSON"
              :ripple="true"
              value="geojson"
              color="#0da344"
            />
            <v-radio
              label="KML"
              :ripple="true"
              value="kml"
              color="#0da344"
            />
            <v-radio
              label="SHP"
              :ripple="true"
              value="shp"
              color="#0da344"
            />
          </v-radio-group>
          <div class="mx-auto mt-6 file-input-width">
            <div
              v-if="
                layerPolygon != '' &&
                  layerPolygon != undefined &&
                  global_layers.isEditing &&
                  layerPolygon != '[object File]'
              "
              class="mb-2 ml-8"
            >
              <v-chip
                small
                label
                color="color_green"
                style="color:white"
              >
                <i>{{ $t('CustomerLayer.existing') }} {{ splitPathFile }}</i>
              </v-chip>
            </div>
          </div>
          <div v-if="global_layers.fileChoice === 'geojson'">
            <div style="margin-top:30px">
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="layerPolygon"
                    accept=".geojson"
                    :label="$t('CustomerLayer.fileinput')"
                    :placeholder="`${$t('CustomerLayer.upload')} GeoJSON`"
                    prepend-inner-icon="mdi-earth"
                    outlined
                    truncate-length="50"
                    :hint="$t('CustomerLayer.check')"
                    @click:clear="closeFileMap"
                    @change="confirmGeojson"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert
                    :value="alertG"
                    color="red accent-4"
                    dark
                    transition="scale-transition"
                  >
                    GeoJSON {{ $t('CustomerLayer.invalid') }}.
                  </v-alert>
                </v-col>
              </v-row>
              <div v-if="localRenderFileMap && !alertG">
                <v-row>
                  <v-col>
                    <layer-map
                      v-if="!global_layers.isEditing || global_layers.isChangingFile"
                      :layer-form="layerForm"
                      :layer-polygon="layerPolygon"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                    <layer-map
                      v-else
                      :layer-form="layerForm"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div v-else-if="global_layers.fileChoice === 'kml' && !global_layers.isTranslating">
            <div style="margin-top:30px">
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="layerPolygon"
                    accept=".kml"
                    :label="$t('CustomerLayer.fileinput')"
                    :placeholder="`${$t('CustomerLayer.upload')} KML`"
                    prepend-inner-icon="mdi-earth"
                    outlined
                    truncate-length="50"
                    :hint="$t('CustomerLayer.check')"
                    @click:clear="closeFileMap"
                    @change="confirmKML"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert
                    :value="alertK"
                    color="red accent-4"
                    dark
                    transition="scale-transition"
                  >
                    KML {{ $t('CustomerLayer.invalid') }}.
                  </v-alert>
                </v-col>
              </v-row>
              <div v-if="localRenderFileMap && !alertK">
                <v-row>
                  <v-col>
                    <layer-map
                      v-if="!global_layers.isEditing || global_layers.isChangingFile"
                      :layer-form="layerForm"
                      :layer-polygon="layerPolygon"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                    <layer-map
                      v-else
                      :layer-form="layerForm"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="global_layers.fileChoice === 'shp' && !global_layers.isTranslating"
              style="margin-top:30px"
            >
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="layerPolygon"
                    accept=".shp,.shx,.cpg,.prj,.qpj,.dbf,.zip"
                    :label="$t('CustomerLayer.fileinput')"
                    :placeholder="`${$t('CustomerLayer.upload')} SHP`"
                    prepend-inner-icon="mdi-earth"
                    outlined
                    truncate-length="50"
                    :hint="$t('CustomerLayer.check')"
                    @click:clear="closeFileMap"
                    @change="confirmSHP"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert
                    :value="alertS"
                    color="red accent-4"
                    dark
                    transition="scale-transition"
                  >
                    SHP {{ $t('CustomerLayer.invalid') }}.
                  </v-alert>
                </v-col>
              </v-row>
              <div v-if="localRenderFileMap && !alertS">
                <v-row>
                  <v-col>
                    <layer-map
                      v-if="!global_layers.isEditing || global_layers.isChangingFile"
                      :layer-form="layerForm"
                      :layer-polygon="layerPolygon"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                    <layer-map
                      v-else
                      :layer-form="layerForm"
                      @closeMap="closeFileMap"
                      @errorMap="errorMapEvent"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, manageLenghtError } from '@/formRules.js';
import { mapState, mapActions } from 'vuex';
// import { S3_BUCKETS } from "@/constants.js";

export default {
  components: {
    LayerMap: () => import('@/components/settings/LayerMap')
  },
  props: {
    layerForm: {
      type: Object,
      default: null
    },
    renderFileMap: {
      type: Boolean,
      default: false
    },
    disableForm: {
      type: Boolean,
      default: false
    },
    selectedLanguage: {
      type: String,
      default: null
    },
    isTranslating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layer_name_rules: [
        ...required,
        ...manageLenghtError(255)
      ],
      description_rules: [...manageLenghtError(512)],
      valid: true,
      overlay: false,
      coordinates: {},
      alertG: false,
      alertK: false,
      alertS: false,
      localRenderFileMap: this.renderFileMap,
      layerPolygon: [],
      delay: ms => new Promise(res => setTimeout(res, ms))
    };
  },
  computed: {
    ...mapState(['global_layers']),
    splitPathFile() {
      const res = this.layerPolygon.split('/');

      return res[1];
    }
  },
  methods: {
    ...mapActions([
      'createGlobalLayerAction',
      'editGlobalLayerAction',
      'storeToastMessage',
      'overlayAction'
    ]),
    confirmGeojson() {
      if (!this.layerPolygon) {
        this.storeToastMessage({
          text: 'please choose a file',
          type: 'warning'
        });
      }

      const reader = new FileReader();

      reader.onload = () => {
        try {
          this.coordinates = JSON.parse(reader.result);
          this.global_layers.isChangingFile = true;
          this.localRenderFileMap = false;
          this.$nextTick(() => {
            this.localRenderFileMap = true;
          });
          this.alertG = false;
        } catch (err) {
          this.alertG = true;
          setTimeout(() => (this.alertG = false), 3000);
        }

        this.global_layers.layer_polygon = JSON.parse(
          JSON.stringify(this.coordinates)
        );
      };

      reader.readAsText(this.layerPolygon);
    },
    closeFileMap() {
      this.localRenderFileMap = false;
      this.$emit('closeCreate');
    },
    errorMapEvent() {
      this.alertG = true;
    },
    async confirmKML() {
      if (!this.layerPolygon) {
        this.storeToastMessage({
          text: 'please choose a file',
          type: 'warning'
        });
      }

      if (!this.layerPolygon.name.includes('.kml')) {
        this.alertK = true;
        setTimeout(() => (this.alertK = false), 3000);
      } else {
        this.global_layers.isChangingFile = true;
        this.localRenderFileMap = false;
        this.$nextTick(() => {
          this.localRenderFileMap = true;
        });
      }
    },
    async confirmSHP() {
      if (!this.layerPolygon) {
        this.storeToastMessage({
          text: 'please choose a file',
          type: 'warning'
        });
      }

      if (
        !this.layerPolygon.name.includes('.zip')
      ) {
        this.alertS = true;
        setTimeout(() => (this.alertS = false), 3000);
      } else {
        this.global_layers.isChangingFile = true;
        this.localRenderFileMap = false;
        this.$nextTick(() => {
          this.localRenderFileMap = true;
        });
      }
    }
  }
};
</script>
